type OptimizeOptions = {
  width?: number | string,
  height?: number | string,
  quality?: number | string,
  background?: string,
  circle?: boolean,
};

const DEFAULT_IMAGE_QUALITY = 75;

export const useCloudinary = () => {
  const URL_REPLACE_PATTERN = 'image/upload';
  const RESOLUTION_MULTIPLIER = 1.5;

  const optimze = (url: string, options: OptimizeOptions) => {
    let customOptions: string = 'f_auto';
    customOptions+= `,q_${options.quality || DEFAULT_IMAGE_QUALITY}`;
    if(options.width) {
      customOptions+= `,w_${+options.width * RESOLUTION_MULTIPLIER}`;
    }
    if(options.height) {
      customOptions+= `,h_${+options.height * RESOLUTION_MULTIPLIER}`;
    }
    if(options.background) {
      customOptions+= `,b_${options.background}`;
    }
    if(options.circle) {
      customOptions+= options.circle ? ',r_max,ar_1' : '';
    }
    const urlReplacement = `${URL_REPLACE_PATTERN}/c_limit,${customOptions}`;
    return url.replace(URL_REPLACE_PATTERN, urlReplacement);
  };

  return {
    optimze,
  };
};
