<template>
  <VAvatar
      :class="['rounded-circle bg-background', classes,]"
      :size="props.size ?? 'auto'"
      :title="props.title"
      :data-cy="props.dataCy"
  >
    <img v-if="props.src"
         :alt="props.alt"
         :class="['max-h-full max-w-full object-cover', props.imgClass,]"
         :height="props.size"
         :src="optimizedSrc"
         :width="props.size"
         :loading="props.lazy ? 'lazy' : 'eager'"
    />
    <VIcon v-else :icon="props.fallbackIcon"/>
  </VAvatar>
</template>
<script setup lang="ts">
import {useCloudinary} from "~/service/useCloudinary";
import {mdiAccount} from "@mdi/js";

const props = withDefaults(defineProps<{
  src: string,
  alt: string,
  class?: String | Record<string, boolean>,
  imgClass?: string | Record<string, boolean>,
  size?: number | string,
  maxSize?: number | string,
  background?: string,
  lazy?: boolean,
  outline: boolean,
  title?: string,
  fallbackIcon: string,
  dataCy?: string,
}>(), {
  lazy: true,
  outline: false,
  fallbackIcon: mdiAccount
})

const {optimze,} = useCloudinary();

const optimizedSrc = computed(() => {
  if (!props.src) {
    return props.fallbackIcon ?? '';
  }
  return optimze(props.src, {
    width: props.size ?? props.maxSize,
    height: props.size ?? props.maxSize,
    quality: props.quality,
    background: props.background,
    circle: true,
  });
});

const classes = computed(()=>{
  return {
  ...(typeof props.class === 'string' ?
      {[props.class]: true,}
      : (props.class)),
  'avatar-outline': props.outline,
  }
});
</script>

<style scoped>

</style>

